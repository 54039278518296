<template>
    <div class="index">
        <van-dropdown-menu>
            <van-dropdown-item v-model="exam_project" :options="option" />
            <van-dropdown-item title="筛选" ref="item">
                <van-cell center title="已做">
                    <template #right-icon>
                        <van-switch v-model="state" size="24" active-color="#ee0a24" />
                    </template>
                </van-cell>
                <div style="padding: 5px 16px;">
                    <van-button type="danger" block round @click="onConfirm">
                        确认
                    </van-button>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>
        <div id="table">
            <table>
                <thead>
                <tr>
                    <th>时段</th>
                    <th>心内</th>
                    <th>心外</th>
                    <th>神内</th>
                    <th>神外</th>
                    <th>下肢</th>
                    <th>普外</th>
                    <th>泌尿</th>
                    <th>介入</th>
                    <th>胃肠</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>24</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>9</td>
                    <td>9</td>
                    <td>5</td>
                    <td>4</td>
                    <td>3</td>
                    <td>2</td>
                    <td>0</td>
                    <td>3</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import {
    Tag,
    Col,
    Cell,
    CellGroup,
    Toast,
    DropdownMenu,
    DropdownItem,
    Button,
    Switch,
    PullRefresh,
    List
} from 'vant';
// import * as dd from 'dingtalk-jsapi';
import axios from 'axios'
import user from '../../mixins/user.js'

export default {
    components: {
        [Tag.name]: Tag,
        [Col.name]: Col,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Button.name]: Button,
        [Switch.name]: Switch,
        [PullRefresh.name]:PullRefresh,
        [List.name]:List
    },

    mounted() {
        console.log(this.user)
        
        
    },
    mixins: [
        user
    ],
    data() {
        return {
            exam_project: '',
            option: [
                { text: '全部', value: '' },
                { text: '16排', value: '16排' },
                { text: 'DR', value: 'DR' },
                { text: '核磁', value: '核磁' },
                { text: '心电', value: '心电' },
                { text: '(彩超)翟成惠', value: '(彩超)翟成惠' },
                { text: '(彩超)石莉莉', value: '(彩超)石莉莉' },
                { text: '(彩超)金虹', value: '(彩超)金虹' },
                { text: '(彩超)郑庆民', value: '(彩超)郑庆民' },
                
            ],
            state: false,
            datas:[],
            isLoading: false,
            loading: false,
            finished: false
        };
    },
    
    methods: {
        
        getData() {
            this.isLoading = true;
            axios.post('http://ydwx.83277777.com/api/bookexam/getData', {exam_project:this.exam_project,state:this.state}).then(res => {
                    this.datas = res.data.data
                    console.log(this.datas)
                    this.isLoading = false;
                    this.loading = false
                }).catch((e) => {
                    console.log(e)
                })
        },

        onConfirm() {
            this.$refs.item.toggle();
            this.getData()
        },
        onRefresh() {
            Toast('刷新成功');
            this.isLoading = false;
        },
        onLoad() {
            this.loading = false
            console.log('loading')
        },
        sorry() {
            Toast('暂无后续逻辑~');
        }
    },
    watch: {
        'exam_project'(val) {
            console.log(val)
            this.getData()
        }
    }
};
</script>
<style lang="less">
    #table {
        overflow-x: scroll;
        text-align: center;
    }
    table { 
      width: 100%; 
      border-collapse: collapse; 
    }
    /* Zebra striping */
    tr:nth-of-type(odd) { 
      background: #eee; 
    }
    th { 
      background: #333; 
      color: white; 
      font-weight: bold; 
    }
    td, th { 
      padding: 6px; 
      border: 1px solid #ccc; 
      text-align: center; 
    }


</style>